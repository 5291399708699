import { CustomCellRendererProps } from "ag-grid-react";
import { DECIMAL_2, INTEGER } from "presentation/utils/numberUtil";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { SupplementaryInvoiceConstant } from "./SupplementaryInvoiceConstant";

const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_SUPPLEMENTARY_INVOICE_DETAIL_COL_DEF: any[] = [
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.DTL_STATE,
        field: 'dtlStateTemp',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 300,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.MANUAL_DETAIL_NO,
        field: 'manualDetailNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        dataType: INTEGER,
        width: 230,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.TAR_DESC,
        field: 'tarDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.DETAIL_DESC_1,
        field: 'dtlDesc1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.DETAIL_DESC_2,
        field: 'dtlDesc2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.CHARGING_FROM_DATE,
        field: 'fromDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "date"
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.CHARGING_TO_DATE,
        field: 'toDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "date"
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.LOG_NO,
        field: 'logNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.ONE_OFF_RATE,
        field: 'rate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.SUR_CHARGE,
        field: 'chargePercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.REVENUE_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.COST_CENTRE_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.PRODUCT_SERVICE_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.COUNTRY_CODE,
        field: 'countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.PROJECT_CODE,
        field: 'projectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.SPARE_CODE,
        field: 'spareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.INVOICE_NO,
        field: 'docNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.DRAFT_INVOICE_NO,
        field: 'draftDocNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.QTY_UOM,
        field: 'individualChargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.CHARGE_IND,
        field: 'chargeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: SUPPLEMENTARY_INVOICE_CONSTANT.EXCEPTION_MSG,
        field: 'exceptionMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['dtlStateTemp'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['dtlState'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}
