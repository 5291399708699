import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_SUPPLEMENTARY_INVOICE_DETAIL_COL_DEF, transferRowData } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceDetailColumnDefinition";
import { useSupplementaryInvoiceDetailVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceDetailVM";
import { useSupplementaryInvoiceDetailTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
const SupplementaryInvoiceDetailTablePanel: React.FC = () => {
    const gridRef: any = useRef(null);
    const [supplementaryInvoiceDetailState] = useSupplementaryInvoiceDetailTracked();
    const supplementaryInvoiceDetailVM = useSupplementaryInvoiceDetailVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const { currentSuppInvoiceHeader } = supplementaryInvoiceDetailState;

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_SUPPLEMENTARY_INVOICE_DETAIL_COL_DEF?.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                currentSuppInvoiceHeader?.id && supplementaryInvoiceDetailVM.onSearch(currentSuppInvoiceHeader),
            ]);
            results?.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {

                } else if (index === 1 && result.status === 'rejected') {

                }
            });
            setIsLoading(false);
        } catch (error) {
        }
    }, [currentSuppInvoiceHeader, supplementaryInvoiceDetailVM]);

    useEffect(() => {
        initialScreen();
    }, [initialScreen])


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        supplementaryInvoiceDetailVM.updateSelectedCharges(selectedRows);
    }, [supplementaryInvoiceDetailVM]);

    const memoSupplementaryInvoiceDetailTable = useMemo(() => {

        return (
            <NbisTable
                id='supplementary-invoice-detail-table'
                isNewColumnSetting={true}
                columns={INITIAL_SUPPLEMENTARY_INVOICE_DETAIL_COL_DEF?.slice()}
                data={transferRowData(supplementaryInvoiceDetailState.suppInvoiceDtls ?? [])}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)"
                ref={gridRef}
            />
        );
    }, [handleSelectionChange, supplementaryInvoiceDetailState.suppInvoiceDtls])


    return <>
        {(isLoading || supplementaryInvoiceDetailState.isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <TableWrapper>
            {memoSupplementaryInvoiceDetailTable}
        </TableWrapper>
    </>;
}
export default memo(SupplementaryInvoiceDetailTablePanel);